.dashboard {
  display: grid;
  min-height: 100vh;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.dashboard--focused {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.dashboard__panel {
  width: 100%;
  height: 50vh;
  font-size: 1rem;
}

.dashboard--focused .dashboard__panel {
  height: 100vh;
}

.dashboard__panel-header {
  font-size: 2em;
  color: white;
  margin: 1rem;
  line-height: 2rem;
}

.CircularProgressbar-text {
  text-align: center;
}

#roomLinkButton {
  border: 3px solid #016668;
}

#roomLinkButton:hover {
  text-shadow: 0px 0px 1px black;
}
